import React from 'react'
import { navigate } from 'gatsby-link'
import { Container, Header, Input, TextArea, Form, Button } from 'semantic-ui-react'
import Layout from '../../components/Layout'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <Container text style={{ margin: '40px 0' }}>
          <Header as="h2">Contact</Header>
          <Form
            name="contact"
            method="post"
            action="/contact/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <Input type="hidden" name="form-name" value="contact" />
            <div hidden>
              <label>
                Don’t fill this out: <Input name="bot-field" onChange={this.handleChange} />
              </label>
            </div>
            <div className="field">
              <label className="label" htmlFor="name">
                Your name
              </label>
              <div className="control">
                <Input
                  className="input"
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  id="name"
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'email'}>
                Email
              </label>
              <div className="control">
                <Input
                  className="input"
                  type="email"
                  name="email"
                  onChange={this.handleChange}
                  id="email"
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'message'}>
                Message
              </label>
              <div className="control">
                <TextArea name="message" onChange={this.handleChange} id="message" required />
              </div>
            </div>
            <div className="field">
              <Button primary type="submit">
                Send
              </Button>
            </div>
          </Form>
        </Container>
      </Layout>
    )
  }
}
